/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for local twilio media.
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Implementations.TwilioLocalStream
 */

import { BackgroundProcessor } from "@twilio/video-processors/es5/processors/background/BackgroundProcessor";
import {
	CreateLocalTrackOptions,
	LocalAudioTrack,
	LocalTrack,
	LocalVideoTrack,
	VideoTrack,
	createLocalAudioTrack,
	createLocalVideoTrack,
} from "twilio-video";
import { IDeviceInitialization } from "~/state/hardwareTest";
import { IDimensions } from "~/types";
import { captureImage } from "~/utils/imageCapture";
import { EVCEmitter, IEVCStreamEventMap } from "~/web-core/events";
import { DeviceKind, ISwitchDeviceResponse } from "~/web-core/types";
import { ILocalStream } from "../../../interfaces/localStream";
import { getVideoTrackOptions, standardAudioTrackOptions } from "../twilioSettings";

export class TwilioLocalStream extends EVCEmitter<IEVCStreamEventMap> implements ILocalStream {
	localVideoTrack?: LocalVideoTrack;
	localAudioTrack?: LocalAudioTrack;
	deviceInitializationError?: IDeviceInitialization;
	readonly isLocal: true = true;

	constructor(tracks: LocalTrack[]) {
		super();
		const videoTrack = tracks.find((track) => track.kind === "video");
		const audioTrack = tracks.find((track) => track.kind === "audio");

		this.localVideoTrack = videoTrack as LocalVideoTrack;
		this.localAudioTrack = audioTrack as LocalAudioTrack;

		this.constructEmitterInterface();
		this.constructAudioEmitterInterface();
	}

	isEnabled(kind: DeviceKind): boolean {
		if (kind === "video") {
			return this.localVideoTrack?.isEnabled ?? false;
		}

		if (kind === "audio") {
			if (this.localAudioTrack?.isStopped) {
				return false;
			} else {
				return this.localAudioTrack?.isEnabled ?? false;
			}
		}

		return false;
	}

	getVideoDimensions(): IDimensions | undefined {
		const dim = this.localVideoTrack?.dimensions;

		if (dim && dim.height && dim.width) {
			return { width: dim.width, height: dim.height };
		}

		return undefined;
	}

	hasAudio(): boolean {
		return this.localAudioTrack !== undefined;
	}

	async captureImage(): Promise<string | null> {
		if (this.localVideoTrack) {
			return captureImage(this.localVideoTrack);
		}

		return null;
	}

	renderVideo(element: HTMLVideoElement): HTMLVideoElement {
		return this.localVideoTrack?.attach(element) as HTMLVideoElement;
	}

	cleanupVideo(element: HTMLVideoElement): HTMLVideoElement {
		return this.localVideoTrack?.detach(element) as HTMLVideoElement;
	}

	getDeviceId(kind: DeviceKind): string {
		if (kind === "audio") {
			return this.localAudioTrack?.mediaStreamTrack.getSettings().deviceId ?? "";
		}

		if (kind === "video") {
			return this.localVideoTrack?.mediaStreamTrack.getSettings().deviceId ?? "";
		}

		return "";
	}

	getDeviceName(kind: DeviceKind): string {
		if (kind === "audio") {
			return this.localAudioTrack?.mediaStreamTrack.label ?? "";
		}

		if (kind === "video") {
			return this.localVideoTrack?.mediaStreamTrack.label ?? "";
		}

		return "";
	}

	async switchVideoDeviceAsync(
		device?: MediaDeviceInfo,
		useLowBandwidth?: boolean,
		constraints?: MediaTrackConstraintSet,
	): Promise<ISwitchDeviceResponse> {
		// we need to detach the video source from any <video> elements before changing its source
		// to avoid rendering freezes on Pixel devices running Android OS 11 (and save for attach below)
		const videoElements = this.localVideoTrack?.detach() ?? null;

		const overrideOptions: CreateLocalTrackOptions = constraints ?? {};

		if (device) {
			overrideOptions.deviceId = { exact: device.deviceId };
		}

		const options = getVideoTrackOptions(!!useLowBandwidth, overrideOptions);

		const onError = async (): Promise<void> => {
			// attempt to reacquire the old video track by restarting w/o constraints
			if (this.localVideoTrack) {
				try {
					await this.localVideoTrack.restart();
				} catch (error) {
					return;
				}
				// attach the detached <video> element(s)
				videoElements?.forEach((element) => {
					this.localVideoTrack?.attach(element);
				});
			}
		};

		try {
			if (this.localVideoTrack) {
				await this.localVideoTrack.restart(options);

				// attach the detached <video> element(s)
				videoElements?.forEach((element) => {
					this.localVideoTrack?.attach(element);
				});
				this.emit("videoEnabled", { type: "videoEnabled" });

				this.localVideoTrack.enable();
				return { result: true, switchedDevices: true, error: undefined };
			} else {
				const newVideoTrack = await createLocalVideoTrack(options);
				this.localVideoTrack = newVideoTrack;
				this.constructEmitterInterface();
				this.emit("videoEnabled", { type: "videoEnabled" });

				return { result: true, switchedDevices: true, error: undefined };
			}
		} catch (error) {
			await onError();
			return { result: false, switchedDevices: false, error: error as Error };
		}
	}

	async removeLocalVideoTrack(): Promise<string | undefined> {
		if (this.localVideoTrack) {
			const deviceId = this.localVideoTrack.mediaStreamTrack.getSettings().deviceId;
			this.localVideoTrack.detach();
			this.localVideoTrack = this.localVideoTrack.disable().stop();

			this.emit("videoDisabled", { type: "videoDisabled" });

			return Promise.resolve(deviceId);
		}

		return Promise.resolve(undefined);
	}

	async switchAudioDeviceAsync(
		device?: MediaDeviceInfo | undefined,
		constraints?: MediaTrackConstraintSet | undefined,
	): Promise<ISwitchDeviceResponse> {
		const overrideOptions: CreateLocalTrackOptions = {};

		if (device) {
			overrideOptions.deviceId = { exact: device.deviceId };
		}

		const options = standardAudioTrackOptions({ ...overrideOptions, ...constraints });

		try {
			if (this.localAudioTrack) {
				const audioMediaStreamTrack = this.localAudioTrack.mediaStreamTrack;
				const currentGroupId = audioMediaStreamTrack?.getSettings().groupId || "1";
				const newGroupId = device?.groupId || "2";
				const switchedPhysicalDevices = currentGroupId !== newGroupId;

				await this.localAudioTrack.restart(options);

				const isAudioEnabled = this.isEnabled("audio");
				this.emit(isAudioEnabled ? "audioEnabled" : "audioDisabled", {
					type: isAudioEnabled ? "audioEnabled" : "audioDisabled",
				});

				return { result: true, switchedDevices: switchedPhysicalDevices, error: undefined };
			} else {
				const newAudioTrack = await createLocalAudioTrack(options);
				this.localAudioTrack = newAudioTrack;
				this.constructAudioEmitterInterface();
				this.emit("audioEnabled", { type: "audioEnabled" });
				return { result: true, switchedDevices: false, error: undefined };
			}
		} catch (error) {
			return { result: false, switchedDevices: false, error: error as Error };
		}
	}

	removeLocalAudioTrack(): void {
		if (this.localAudioTrack) {
			this.localAudioTrack.stop();
			this.emit("audioDisabled", { type: "audioDisabled" });
		}
	}

	toggleState(kind: DeviceKind, turnOn: boolean): void {
		if (kind === "audio") {
			if (turnOn) {
				this.localAudioTrack?.enable();
				if (this.isEnabled("audio")) {
					this.emit("audioEnabled", { type: "audioEnabled" });
				}
			} else {
				this.localAudioTrack?.disable();
				this.emit("audioDisabled", { type: "audioDisabled" });
			}
		}

		if (kind === "video") {
			if (turnOn) {
				this.localVideoTrack?.enable();
				this.emit("videoEnabled", { type: "videoEnabled" });
			} else {
				this.localVideoTrack?.disable();
				this.emit("videoDisabled", { type: "videoDisabled" });
			}
		}
	}

	getMediaStreamTrack(kind: DeviceKind): MediaStreamTrack | undefined {
		if (kind === "audio") {
			return this.localAudioTrack?.mediaStreamTrack;
		}

		if (kind === "video") {
			return this.localVideoTrack?.mediaStreamTrack;
		}

		return undefined;
	}

	getDeviceInitializationError(): IDeviceInitialization | undefined {
		return this.deviceInitializationError;
	}

	cleanUp(): void {
		this.localAudioTrack?.detach();
		this.localVideoTrack?.detach();
		this.localAudioTrack?.stop();
		this.localVideoTrack?.stop();

		// Remove event listeners last so any other events have a chance to fire to consuming components
		this.localVideoTrack?.removeAllListeners();
		this.localAudioTrack?.removeAllListeners();
	}

	/**
	 * Semi-private method not exposed on the interface. Applies a video background to
	 * the video track, should only be called by the local user.
	 * @param processor The background processor to apply to the video track
	 */
	applyVideoBackground(processor: BackgroundProcessor | null): void {
		if (!this.localVideoTrack?.isEnabled) {
			return;
		}

		if (this.localVideoTrack?.processor) {
			this.localVideoTrack.removeProcessor(this.localVideoTrack.processor);
		}

		if (processor) {
			this.localVideoTrack?.addProcessor(processor);
		}
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by evcEvent
	 */
	private constructEmitterInterface(): void {
		this.localVideoTrack?.on("dimensionsChanged", (track) => this.emitVideoDimensions(track));
		this.localVideoTrack?.on("started", (track) => {
			this.emit("videoReady", { type: "videoReady", track: track.mediaStreamTrack });
			this.emitVideoDimensions(track);
		});
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by evcEvent
	 */
	private constructAudioEmitterInterface(): void {
		this.localAudioTrack?.on("started", (track) => {
			this.emit("audioReady", { type: "audioReady", track: track.mediaStreamTrack });
		});
	}

	/**
	 * Emits a videoDimensionsChanged event with the dimensions of the video track
	 */
	private emitVideoDimensions(track: VideoTrack): void {
		const width = track.dimensions.width;
		const height = track.dimensions.height;
		this.emit("videoDimensionsChanged", {
			type: "videoDimensionsChanged",
			newDim: { height: height ?? 0, width: width ?? 0 },
		});
	}
}
