/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file apply background button
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.ApplyBackgroundButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { useLocalVideoToggle, useStrings } from "~/hooks";
import InformationCircle from "~/icons/informationCircle";
import Success from "~/icons/success";
import { useHardwareTestState, useLocalTrackState } from "~/state";
import { backgroundProcessorsActions, useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { uiActions } from "~/state/ui";
import { DeviceStatus } from "~/types";
import { MenuBackgroundColor } from "~/types/backgrounds";
import { VideoContext } from "~/web-core/components";
import ActionButton from "../Utilities/ActionButton";
import styles from "./ApplyBackgroundButton.module.scss";

export enum TokenNames {
	applyTooltip = "ApplyTooltip",
	applyBackground = "ApplyBackground",
	applyBackgroundCamera = "ApplyBackgroundCamera",
	backgroundCameraOff = "BackgroundCameraOff",
	backgroundNotApplied = "BackgroundNotApplied",
	backgroundNoCamera = "BackgroundNoCamera",
}

/**
 * Applies current previewProcessor to the published track
 * @param props The props ;)
 */
const ApplyBackgroundButton: FC = () => {
	const dispatch = useDispatch();

	const { session } = useContext(VideoContext);
	const localUser = session?.localUser;

	const processorMap = useBackgroundProcessorsState((selectors) => selectors.getBackgroundProcessors(), []);

	const [enabledPublishedVideoTrack, togglePublishedVideoTrack] = useLocalVideoToggle();

	const cameraStatus = useHardwareTestState((selectors) => selectors.getCameraStatus(false), []);
	const isCameraToggling = !!useLocalTrackState((selectors) => selectors.getTogglingCamera(), []);
	const previewProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPreviewBackgroundProcessor(),
		[],
	);
	const publishedProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPublishedBackgroundProcessor(),
		[],
	);

	const isSameProcessor = previewProcessor === publishedProcessor;
	const isCameraConnected = cameraStatus === DeviceStatus.success;

	const strings = useStrings("ApplyBackgroundButton", Object.values(TokenNames));

	// apply button callback to send preview processor to published video track
	const applyOnClick = useCallback(async () => {
		if (!localUser) {
			return;
		}
		// Close menu after apply button is clicked
		dispatch(uiActions.toggleVisibleMenu({ menu: null }));

		dispatch(backgroundProcessorsActions.setPublishedBackgroundProcessor(previewProcessor));

		if (!enabledPublishedVideoTrack) {
			await togglePublishedVideoTrack("on", previewProcessor);
		} else {
			const processorSettings = processorMap.get(previewProcessor)?.processor ?? null;
			await localUser.applyVideoBackground(processorSettings, null);
		}
	}, [
		localUser,
		dispatch,
		enabledPublishedVideoTrack,
		togglePublishedVideoTrack,
		previewProcessor,
		processorMap,
	]);

	const applyMessage = useMemo(() => {
		if (!isCameraConnected) {
			return strings[TokenNames.backgroundNoCamera];
		} else if (!enabledPublishedVideoTrack) {
			return strings[TokenNames.backgroundCameraOff];
		} else {
			return strings[TokenNames.backgroundNotApplied];
		}
	}, [strings, enabledPublishedVideoTrack, isCameraConnected]);

	const buttonText = enabledPublishedVideoTrack
		? strings[TokenNames.applyBackground]
		: strings[TokenNames.applyBackgroundCamera];

	return (
		<div className={styles["applyRow"]}>
			{!isSameProcessor && (
				<div className={styles["visibilityInfoWrapper"]}>
					<InformationCircle
						baseColor={MenuBackgroundColor} // $menu-background-color: #222
						outlineColor="white"
						height={30}
						width={30}
						aria-hidden
					/>
					<label>{applyMessage}</label>
				</div>
			)}
			<div className={styles["applyButton"]}>
				<ActionButton
					tone="positive"
					priority="primary"
					icon={Success}
					onClick={applyOnClick}
					keyboardShortcut={["alt", "a"]}
					text={buttonText}
					disabled={!isCameraConnected || isSameProcessor || isCameraToggling}
				/>
			</div>
		</div>
	);
};

ApplyBackgroundButton.displayName = "ApplyBackgroundButton";

export default ApplyBackgroundButton;
