/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Utilities for managing Date objects and date parsing
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.DateTime
 */

const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;
const MILLISECONDS_PER_SECOND = 1000;

/**
 * Convenience method to convert hours to milliseconds for readability
 *
 * @export
 * @param hours number of hours to convert to milliseconds
 * @returns milliseconds representation of hours duration
 */
export function hoursToMs(hours: number): number {
	return hours * MINUTES_PER_HOUR * SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;
}

/**
 * Convenience method to convert minutes to milliseconds for readability
 *
 * @export
 * @param minutes number of minutes to convert to milliseconds
 * @returns milliseconds representation of minutes duration
 */
export function minutesToMs(minutes: number): number {
	return minutes * SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;
}

/**
 * Convenience method to convert seconds to milliseconds for readability
 *
 * @export
 * @param seconds number of seconds to convert to milliseconds
 * @returns milliseconds representation of seconds duration
 */
export function secondsToMs(seconds: number): number {
	return seconds * MILLISECONDS_PER_SECOND;
}

/**
 * Convenience method to convert milliseconds to seconds
 * @export
 * @param timeMs number of milliseconds to convert to seconds
 * @returns seconds representation of milliseconds duration
 */
export function msToSeconds(timeMs: number): number {
	return Math.round(timeMs / MILLISECONDS_PER_SECOND);
}

/**
 * Format a duration for display
 * @param timeMs time to format in milliseconds
 * @returns the formatted time
 */
export function getFormattedTime(timeMs: number): string {
	let seconds = Math.round(timeMs / MILLISECONDS_PER_SECOND);
	const hours = Math.floor(seconds / (MINUTES_PER_HOUR * SECONDS_PER_MINUTE));
	seconds %= MINUTES_PER_HOUR * SECONDS_PER_MINUTE;
	const minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
	seconds %= SECONDS_PER_MINUTE;

	const formatToTwoDigits = (num: number): string => ("0" + num.toString()).slice(-2);

	let returnVal = hours ? hours.toString() + ":" + formatToTwoDigits(minutes) : minutes.toString();
	returnVal += ":" + formatToTwoDigits(seconds);

	return returnVal;
}

/**
 * Formats an ISO string into a display string showing the visit time.
 * There are issues with directly formatting ISO strings that do not contain timezone information on iOS Safari versions < 13.3 so we will provide that context when the default value does not.
 * @param isoDateString - Date string in ISO format.
 * @param locale - The locale to use for formatting the date.
 * @param formatOption - Optional parameter to specify if the date should be formatted as time only, date only, or both date and time. Default is both date and time.
 * Example (1:01:00 AM - January 1st, 2021) - 2021-01-01T01:01:00.0000000(-7:00 / +7:00 if timezone is supplied)
 * @returns the formatted string for the visit as best it can be approximated by parsing and formatting the ISO string
 */
export function formatDateDisplayString(
	isoDateString: string | null,
	locale: string,
	formatOption?: "timeOnly" | "dateOnly",
): string | null {
	if (!isoDateString) {
		return null;
	}
	try {
		const isoTime = isoDateString.split("T")[1];
		let hasTzMarker = true;
		// If ISO string does not contain timezone information, attach local time zone information to avoid inaccurate time zone conversion
		if (!isoTime.toLowerCase().includes("z") && !isoTime.includes("-") && !isoTime.includes("+")) {
			isoDateString += getLocalTimeZoneOffsetString();
			hasTzMarker = false;
		}

		if (locale === "zz-epic") {
			locale = "en-gb";
		}

		const dateTimeOptions: Intl.DateTimeFormatOptions =
			formatOption === "timeOnly"
				? {
						hour: "numeric",
						minute: "numeric",
				  }
				: formatOption === "dateOnly"
				? {
						month: "long", // Use long format so that screen readers can read dates correctly (issue #368).
						day: "numeric",
						year: "numeric",
				  }
				: {
						month: "long", // Use long format so that screen readers can read dates correctly (issue #368).
						day: "numeric",
						year: "numeric",
						hour: "numeric",
						minute: "numeric",
				  };

		if (hasTzMarker) {
			dateTimeOptions.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			if (formatOption !== "dateOnly") {
				dateTimeOptions.timeZoneName = "short";
			}
		}

		// date time format to use (ex. 1/1/2021, 10:00 AM)
		const dateTimeFormat = Intl.DateTimeFormat([locale], dateTimeOptions);

		return dateTimeFormat.format(new Date(isoDateString));
	} catch (error) {
		// if the date couldn't be formatted, return null
		return null;
	}
}

/**
 * Use the current timezone offset to determine the local timezone offset
 * @returns A formatted string of the local timezone offset
 * Either -HOUR:MINUTES or +HOUR:MINUTES depending on where the date falls in relation to UTC
 */
function getLocalTimeZoneOffsetString(): string {
	// Flip the value of the time-offset according to mozilla documentation
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
	const tzOffset = -new Date().getTimezoneOffset();

	// Parse minutes and hours from the offset
	const absTzOffset = Math.abs(tzOffset);
	const hours = Math.floor(absTzOffset / MINUTES_PER_HOUR);
	const minutes = absTzOffset % MINUTES_PER_HOUR;

	// Format the values so the Date object can be parsed from the ISO string properly.
	// Requires two-digits in the hour and minute. "07:00" not "7:00"
	const sign = tzOffset / absTzOffset < 0 ? "-" : "+";
	const minutesStr = ("0" + minutes.toString()).slice(-2);
	const hourStr = ("0" + hours.toString()).slice(-2);
	return sign + hourStr + ":" + minutesStr;
}
