/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file component to include when audio needs to be analyzed conditionally
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.Audio.AudioAnalyzer
 */

import { FC, useEffect, useRef } from "react";
import { volumeDetectionThreshold } from "~/types";
import { useAudioLevel } from "~/web-core/hooks/useAudioLevel";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { IStream } from "~/web-core/interfaces";

/**
 * Props for AudioAnalyzer Component
 */
interface IProps {
	stream: IStream;
	onSpeakingChange: (val: boolean) => void;
	onIsTrackEnabledChanged?: (val: boolean) => void;
}

/**
 * The AudioAnalyzer component. The useAudioLevel hook can't be included conditionally, but a component can be :)
 * Prevent having to do any tricks to avoid analyzing audio when we don't need to by conditionally including component
 * @param props The props ;)
 */
const AudioAnalyzer: FC<IProps> = (props) => {
	const { stream, onIsTrackEnabledChanged, onSpeakingChange } = props;
	const onTrackEnabledRef = useRef(onIsTrackEnabledChanged);
	const onSpeakingRef = useRef(onSpeakingChange);
	const prevActiveRef = useRef(false);

	useEffect(() => {
		onTrackEnabledRef.current = onIsTrackEnabledChanged;
	}, [onIsTrackEnabledChanged]);

	useEffect(() => {
		onSpeakingRef.current = onSpeakingChange;
	}, [onSpeakingChange]);

	const isStreamEnabled = useIsStreamEnabled("audio", stream);
	const volumeLevel = useAudioLevel(stream);

	useEffect(() => {
		if (onTrackEnabledRef.current) {
			onTrackEnabledRef.current(isStreamEnabled);
		}
	}, [isStreamEnabled]);

	useEffect(() => {
		// When volume changes, only update the consuming component when there is a change in meaningful audio
		const isActive = volumeLevel > volumeDetectionThreshold;
		if (prevActiveRef.current !== isActive) {
			prevActiveRef.current = isActive;
			onSpeakingRef.current(isActive);
		}
	}, [volumeLevel]);

	return null;
};

AudioAnalyzer.displayName = "AudioAnalyzer";

export default AudioAnalyzer;
