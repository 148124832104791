/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file in-header controls
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.InHeaderControls
 */

import React, { FC, useContext } from "react";
import { useWindowSize } from "~/hooks";
import { useUserState } from "~/state";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import { useChatState } from "../../state/chat";
import { CallDurationTimerDestination } from "../CallDurationTimer/CallDurationTimerDestination";
import NetworkQualityIndicator from "../NetworkQualityIndicator/NetworkQualityIndicator";
import ChangeCameraButton from "./Buttons/ChangeCameraButton";
import ChatButton from "./Buttons/ChatButton";
import DisableCameraButton from "./Buttons/DisableCameraButton";
import LeaveCallButton from "./Buttons/LeaveCallButton";
import MoreMenuButton from "./Buttons/MoreMenuButton";
import MuteButton from "./Buttons/MuteButton";
import OpenRequestDeviceMenuButton from "./Buttons/OpenRequestDeviceMenuButton";
import ParticipantsButton from "./Buttons/ParticipantsButton";
import PictureInPictureButton from "./Buttons/PictureInPictureButton";
import ScreenShareButton from "./Buttons/ScreenShareButton";
import ToggleButtonTrayButton from "./Buttons/ToggleButtonTrayButton";
import styles from "./ControlsHeader.module.scss";
import { MinWidthForChangeCamInHeader, MinWidthForPiPButtonInHeader } from "./Menu/BaseMenu";
import { useHeaderDisplayMode } from "./hooks/useHeaderDisplayMode";
import { useShowParticipantButtonInHeader } from "./hooks/useShowParticipantButtonInHeader";

const MORE_OPTIONS_LABEL_THRESHOLD = 1700;
const LEAVE_LABEL_THRESHOLD = 1400;

export enum InHeaderControlsTestIds {
	controlsInHeaderMenuButtons = "MenuButtons",
	controlsInHeaderButtons = "ButtonsGrouping",
	controlsInHeaderLeaveButton = "LeaveButton",
	noControlsInHeader = "noHeaderControls",
	networkAndTimerGrouping = "NetworkAndTimer",
}

/**
 * The InHeaderControls component
 */
const InHeaderControls: FC = () => {
	const { width } = useWindowSize();
	const { session } = useContext(VideoContext);

	const headerDisplayMode = useHeaderDisplayMode();
	const showChangeCamera = width >= MinWidthForChangeCamInHeader;
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const showParticipantButton = useShowParticipantButtonInHeader();
	const showMoreOptionsButtonLabel = width >= MORE_OPTIONS_LABEL_THRESHOLD;
	const showLeaveCallButtonLabel = width >= LEAVE_LABEL_THRESHOLD;
	const showPiPButton = width >= MinWidthForPiPButtonInHeader;

	const chatEnabled = useChatState((selectors) => selectors.getChatEnabled(), []);

	// to ensure the timer stays accurate when the header is hidden, CallDurationTimer is always
	// rendered in VideoRoom, but CallDurationTimerDestination can conditionally be in the DOM
	return (
		<>
			<div
				className={styles["buttonGrouping"]}
				data-testid={InHeaderControlsTestIds.networkAndTimerGrouping}
			>
				<NetworkQualityIndicator
					participant={session?.getLocalParticipant() ?? undefined}
					backgroundColor="grey"
				/>
				<CallDurationTimerDestination />
			</div>
			{headerDisplayMode === "controls-in-header" ? (
				<>
					<div
						className={styles["buttonGrouping"]}
						data-testid={InHeaderControlsTestIds.controlsInHeaderMenuButtons}
					>
						<MoreMenuButton noLabel={!showMoreOptionsButtonLabel} />
						{showChangeCamera && <ChangeCameraButton noLabel />}
						{showParticipantButton && <ParticipantsButton inHeader />}
					</div>
					<div
						className={styles["buttonGrouping"]}
						data-testid={InHeaderControlsTestIds.controlsInHeaderButtons}
					>
						{!inWaitingRoom && showPiPButton && <PictureInPictureButton inHeader noLabel />}
						<MuteButton />
						<DisableCameraButton />
						{!inWaitingRoom && <ScreenShareButton noLabel />}
						<OpenRequestDeviceMenuButton />
						{!inWaitingRoom && chatEnabled && <ChatButton noLabel />}
					</div>
					<div
						className={styles["buttonGrouping"]}
						data-testid={InHeaderControlsTestIds.controlsInHeaderLeaveButton}
					>
						<LeaveCallButton noLabel={!showLeaveCallButtonLabel} />
					</div>
				</>
			) : (
				<div
					className={styles["buttonGrouping"]}
					data-testid={InHeaderControlsTestIds.noControlsInHeader}
				>
					<ToggleButtonTrayButton />
				</div>
			)}
		</>
	);
};

InHeaderControls.displayName = "InHeaderControls";

export default InHeaderControls;
