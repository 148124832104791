/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Generic interface for remote media
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.RemoteStream
 */

import { IEVCEmitter, IEVCStreamEventMap } from "../events";
import { IStream } from "./stream";

/**
 * Interface for mocking a remote user's audio/video and the underlying types that represent their devices.
 */
export interface IRemoteStream extends IEVCEmitter<IEVCStreamEventMap>, IStream {
	readonly isLocal: false;

	/**
	 * Method to check if a remote stream has had quality reduced due to low bandwidth
	 * @returns true if the stream is bandwidth limited, false otherwise
	 */
	isBandwidthLimited(): boolean;
}
