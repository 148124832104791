/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to get some functions to change the audio track
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.LocalTracks.UseAudioTrackActions
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { useStrings } from "~/hooks";
import { alertActions, hardwareTestActions } from "~/state";
import { localTrackActions } from "~/state/localTracks";
import { DeviceStatus, DeviceStatusSubtype } from "~/types";
import { getMicPreference, setMicPreference } from "~/utils/device";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";

interface IAudioTrackActions {
	switchAudioDevice: (
		device?: MediaDeviceInfo,
		manual?: boolean,
		constraints?: MediaTrackConstraintSet,
	) => Promise<void>;
	removeLocalAudioTrack: (manual?: boolean) => void;
	restartLocalAudioTrack: (shouldToggleAudio: boolean, enableAudio?: boolean) => Promise<void>;
}

export function useAudioTrackActions(): IAudioTrackActions {
	const { audioContext } = useContext(DeviceContext);
	const { stream, session } = useContext(VideoContext);

	const dispatch = useDispatch();

	const tokenNames = ["DefaultError", "NotAllowedError"];
	const strings = useStrings("useAudioTrackActions", tokenNames);

	const stringsRef = useRef(strings);
	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	const removeLocalAudioTrack = useCallback(
		(manual?: boolean): void => {
			if (stream) {
				stream?.removeLocalAudioTrack();
				if (session?.connectionStatus === "connected") {
					void session?.publish("audio", false, stream);
				}

				dispatch(
					hardwareTestActions.setMicrophoneState({
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.noDevice,
					}),
				);
			}

			if (!manual) {
				dispatch(localTrackActions.setHasAutoSelectedAudio());
			}
		},
		[stream, dispatch, session],
	);

	const switchAudioDevice = useCallback(
		async (
			device?: MediaDeviceInfo,
			manual?: boolean,
			constraints?: MediaTrackConstraintSet,
		): Promise<void> => {
			const overrides = constraints ?? {};

			const onSuccess = (): void => {
				// remember preference if this is a manual selection. Also, device IDs may change, so if
				// this is an auto-select based on device label, update the stored preference's ID
				const pref = getMicPreference();
				if (device && (manual || (pref?.label === device.label && pref.id !== device.deviceId))) {
					setMicPreference(device);
				}
			};

			const onError = (error: DOMException): void => {
				// if we get an error w/o providing device / constraints, there are probably deeper issues
				if (!device && !constraints) {
					// if we null things out, at least we have a chance at recovery via audio toggle fallback
					stream?.removeLocalAudioTrack();

					const message = stringsRef.current["DefaultError"];
					dispatch(alertActions.postGenericAlert(message));
					return;
				}
				if (manual) {
					const message =
						error.name === "NotAllowedError"
							? stringsRef.current["NotAllowedError"]
							: stringsRef.current["DefaultError"];
					dispatch(alertActions.postGenericAlert(message));
				} else {
					// with no device or constraints params, if this fails it will get caught at the top of onError
					void switchAudioDevice();
				}
			};

			const onFinished = (): void => {
				if (!manual) {
					dispatch(localTrackActions.setHasAutoSelectedAudio());
				}
			};

			if (audioContext) {
				overrides.sampleRate = audioContext.sampleRate;
			}

			const audioSwitchResult = await stream?.switchAudioDeviceAsync(device, overrides);
			if (audioSwitchResult?.error) {
				onError(audioSwitchResult.error as DOMException);
			}

			if (audioSwitchResult?.result === true) {
				if (stream && session?.connectionStatus === "connected") {
					await session?.publish("audio", true, stream);
				}
				onSuccess();
				if (audioSwitchResult?.switchedDevices) {
					dispatch(hardwareTestActions.setMicrophoneSuccess());
				}
				dispatch(localTrackActions.setCanCreateAudioContext(true));
			}

			onFinished();
		},
		[audioContext, stream, dispatch, session],
	);

	const restartLocalAudioTrack = useCallback(
		async (shouldToggleAudio: boolean, enableAudio?: boolean): Promise<void> => {
			if (!stream) {
				return switchAudioDevice();
			}
			const deviceId = stream.getDeviceId("audio");

			/** iOS: if you start a call w/Airpods, that is the deviceId & label we'll store. Even disconnected it will persist
			 * in our state store since we don't listen for updates. So if we attempt to restart via the device param it will
			 * cause an OverconstrainedError due to 'exact'. So, we'll add the option for generic constraints and use 'ideal'
			 */
			const constraints = { deviceId: { ideal: deviceId } };

			await switchAudioDevice(undefined, false, constraints).then(() => {
				if (shouldToggleAudio) {
					stream.toggleState("audio", enableAudio ?? false);
				}
			});
		},
		[stream, switchAudioDevice],
	);

	return { switchAudioDevice, removeLocalAudioTrack, restartLocalAudioTrack };
}
