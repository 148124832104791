/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file in-call trays: drop down menu & button try on small screens
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Trays
 */

import React, { FC } from "react";
import Banner from "./Banner/Banner";
import ButtonTray from "./ButtonTray";
import styles from "./ControlsHeader.module.scss";
import { useHeaderDisplayMode } from "./hooks/useHeaderDisplayMode";
import { MoreOptionsMenuWrapperId } from "./Menu/DropDownMenus";
import Toasts from "./Toasts/Toasts";

export enum TraysTestIds {
	controlsInHeader = "ControlsInHeader",
	buttonTray = "ButtonTray",
	noHeader = "NoHeader",
}

/**
 * The Trays component
 */
const Trays: FC = () => {
	const headerDisplayMode = useHeaderDisplayMode();

	if (headerDisplayMode === "controls-in-header") {
		// don't absolutely position the banner within the trays div to avoid covering participants
		return (
			<>
				<Banner />
				<div className={styles["trays"]} data-testid={TraysTestIds.controlsInHeader}>
					<Toasts />
				</div>
			</>
		);
	}

	if (headerDisplayMode === "button-tray") {
		return (
			<div className={styles["trays"]}>
				<div
					className={styles["trayAndToastsWrapper"]}
					id={MoreOptionsMenuWrapperId}
					data-testid={TraysTestIds.buttonTray}
				>
					<ButtonTray />
					<Banner />
					<Toasts />
				</div>
			</div>
		);
	}

	if (headerDisplayMode === "no-header") {
		// don't absolutely position the button tray within the trays div to avoid covering participants
		return (
			<>
				<ButtonTray id={MoreOptionsMenuWrapperId} />
				<div className={styles["trays"]} data-testid={TraysTestIds.noHeader}>
					<Banner />
					<Toasts />
				</div>
			</>
		);
	}

	// if a new format gets added we'll have to define how it gets formatted
	return null;
};

Trays.displayName = "Trays";

export default Trays;
