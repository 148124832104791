/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file in call control button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ControlButton
 */

import React, { ComponentType, FC } from "react";
import { IconProps } from "~/icons";
import Chevron, { Direction } from "~/icons/chevron";
import { resolveClassName } from "~/utils/className";
import BaseButton from "../../Utilities/BaseButton";
import { ITooltipInfo } from "../../Utilities/Tooltip";
import styles from "../ControlsHeader.module.scss";

// when using style modules CSS class names are modified to remain unique between modules
// export the actual selector that can be used to find ControlButton elements in the DOM
const baseClassName = "controlButton";
export const controlButtonSelector = `.${styles[baseClassName]}`;

type SupplementaryIcon = "continue" | "chevron-up" | "chevron-down";

/**
 * Props for ControlButton Component
 */
interface IProps {
	icon: ComponentType<IconProps>;
	onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
	tone?: "neutral" | "negative";
	ariaLabel: string;
	pressed?: boolean;
	buttonClassName?: string;
	keyboardShortcut: string[];
	buttonText?: string;
	tooltipText?: string;
	supplementaryIcon?: SupplementaryIcon | null;
	id?: string;
	onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
	ariaExpanded?: boolean;
	disabledTabbable?: boolean;
}

/**
 * Base component for control buttons that can be used in the button tray and header
 * @param props the props ;)
 */
const ControlButton: FC<IProps> = (props) => {
	const {
		icon: Icon,
		buttonText,
		onClick,
		tone,
		pressed,
		ariaLabel,
		buttonClassName,
		keyboardShortcut,
		supplementaryIcon,
		tooltipText,
		id,
		onKeyDown,
		ariaExpanded,
		disabledTabbable,
	} = props;

	const className = resolveClassName(
		styles,
		{ [baseClassName]: true, negative: tone === "negative" },
		buttonClassName,
	);

	let tooltipInfo: ITooltipInfo | undefined;
	if (tooltipText) {
		tooltipInfo = { text: tooltipText, direction: "right" };
	}

	return (
		<BaseButton
			rawClass={className}
			onClick={onClick}
			aria-label={ariaLabel}
			aria-pressed={pressed}
			aria-expanded={ariaExpanded}
			onKeyDown={onKeyDown}
			id={id}
			icon={Icon}
			keyboardShortcut={keyboardShortcut}
			text={buttonText}
			tooltipInfo={tooltipInfo}
			disabledTabbable={disabledTabbable}
		>
			{getSupplementaryIcon(supplementaryIcon)}
		</BaseButton>
	);
};

/**
 * Get the supplementary icon to include on the ControlButton.
 * This icon will be displayed on the right side of the button to help add context.
 *
 * @param icon which supplementary icon should be included, if any
 * @returns the ReactNode to include for the supplement icon
 */
function getSupplementaryIcon(icon?: SupplementaryIcon | null): React.ReactNode {
	if (!icon) {
		return null;
	}

	let direction: Direction;
	let iconSize = 20;
	if (icon === "continue") {
		direction = "right";
		iconSize = 15;
	} else if (icon === "chevron-up") {
		direction = "up";
	} else {
		direction = "down";
	}

	return <Chevron height={iconSize} width={iconSize} aria-hidden chevronDirection={direction} />;
}

ControlButton.displayName = "ControlButton";

export default ControlButton;
