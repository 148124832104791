/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file HeaderBar component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.Header.HeaderBar
 */

import React, { FC } from "react";
import EpicLogo from "~/icons/epicLogo";
import BrandedLogo from "../Branding/BrandedLogo";
import styles from "./HeaderBar.module.scss";
import { MoreOptionsMenuWrapperId } from "./Menu/DropDownMenus";
import { useHeaderDisplayMode } from "./hooks/useHeaderDisplayMode";

export enum HeaderBarTestIds {
	self = "HeaderBar",
}

interface IProps {
	alwaysVisible?: boolean;
}

/**
 * The HeaderBar component
 * @param props The props ;)
 */
const HeaderBar: FC<IProps> = (props) => {
	const { alwaysVisible, children } = props;
	const headerDisplayMode = useHeaderDisplayMode();

	if (!alwaysVisible && headerDisplayMode === "no-header") {
		return null;
	}

	return (
		<div
			className={styles["headerBar"]}
			id={headerDisplayMode === "controls-in-header" ? MoreOptionsMenuWrapperId : ""}
			data-testid={HeaderBarTestIds.self}
		>
			<div className={styles["epicLogoWrapper"]}>
				<EpicLogo className={styles["epicLogo"]} height={40} width={100} aria-hidden />
			</div>
			<BrandedLogo />
			{children && <div className={styles["childrenWrapper"]}>{children}</div>}
		</div>
	);
};

HeaderBar.displayName = "HeaderBar";

export default HeaderBar;
