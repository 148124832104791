/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file full screen icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Fullscreen
 */

import React, { FC } from "react";
import { IconProps } from ".";
import styles from "../styles/_directional.scss";

export enum FullscreenTestIds {
	self = "Fullscreen",
}

/**
 * The Fullscreen component
 * @param props The props ;)
 */
const Fullscreen: FC<IconProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			className={styles["flipWithPage"]}
			data-testid={FullscreenTestIds.self}
			{...props}
		>
			<path d="M14,24H8V8H24v6a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H7A1,1,0,0,0,6,7V25a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1h0A1,1,0,0,0,14,24Z" />
			<path d="M15.21,13.79,23,21.59A1,1,0,0,1,23,23h0a1,1,0,0,1-1.41,0l-7.8-7.79Z" />
			<path d="M13,13h6a1,1,0,0,1,0,2H15v4a1,1,0,0,1-2,0Z" />
		</svg>
	);
};

Fullscreen.displayName = "Fullscreen";

export default React.memo(Fullscreen);
