/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file hook to retrieve the currently selected mic ID from the LocalVideoTrack or disabled mic in shared state
 * @author Max Harkins
 * @module Epic.VideoApp.Hooks.UseSelectedMicId
 */

import { useContext } from "react";
import { VideoContext } from "~/web-core/components";
import { useMediaTrack } from "~/web-core/hooks/useMediaTrack";

/**
 * Get the selected microphone ID from shared state
 */
export function useSelectedMicId(): string {
	const { stream } = useContext(VideoContext);
	const mediaStreamTrack = useMediaTrack(stream, "audio");
	return mediaStreamTrack?.getSettings().deviceId ?? "";
}
