/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component to manage the active speaker alert that appears when using grid mode
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.DominantSpeakerAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useContext, useEffect, useState } from "react";
import AudioAnalyzer from "~/components/Participants/Audio/AudioAnalyzer";
import { useParticipantName } from "~/components/Participants/hooks/useParticipantName";
import { alertActions, useUIState } from "~/state";
import { VideoCallContext } from "~/web-core/components";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";

interface IProps {
	visibleParticipants: IUser[];
}

enum TokenNames {
	namedActiveSpeaker = "ActiveSpeaker",
	defaultActiveSpeaker = "ActiveSpeaker_DefaultName",
}

/**
 * The DominantSpeakerAlert component
 */
const DominantSpeakerAlert: FC<IProps> = (props: IProps) => {
	const { visibleParticipants } = props;
	const dispatch = useDispatch();

	const { dominantSpeaker } = useContext(VideoCallContext);
	const domSpeakerStream = useStream(dominantSpeaker ?? undefined, "camera");
	const activeSpeakerDisplayName = useParticipantName(dominantSpeaker);
	const [isEnabled, setIsEnabled] = useState(false);
	const [isSpeaking, setIsSpeaking] = useState(false);
	const pageCount = useUIState((selectors) => selectors.getPageCount(), []);

	// Watch for dominant speaker changes. When there is a valid dominant speaker, show a new toast alert that they are speaking.
	// If no one is speaking, don't show the alert at all.
	useEffect(() => {
		if (dominantSpeaker && isSpeaking && isEnabled) {
			if (
				visibleParticipants.some(
					(current) => current.getUserIdentity() === dominantSpeaker.getUserIdentity(),
				)
			) {
				// Only show the alert if the dominant speaker is off screen
				dispatch(alertActions.clearToasts("active-speaker"));
				return;
			}
			let messageToken = TokenNames.defaultActiveSpeaker;
			let messageArgs: string[] | undefined;

			if (activeSpeakerDisplayName) {
				messageToken = TokenNames.namedActiveSpeaker;
				messageArgs = [activeSpeakerDisplayName];
			}

			dispatch(
				alertActions.postToastAlert({
					type: "active-speaker",
					messageToken: messageToken,
					messageArgs: messageArgs,
				}),
			);
		} else {
			dispatch(alertActions.clearToasts("active-speaker"));
		}
	}, [activeSpeakerDisplayName, dispatch, dominantSpeaker, isEnabled, isSpeaking, visibleParticipants]);

	useEffect(() => {
		// Run cleanup code only on a full unmount
		return () => {
			dispatch(alertActions.clearToasts("active-speaker"));
		};
	}, [dispatch]);

	// Don't do the audio analysis unless we have multiple pages and a dominant speaker to validate
	if (pageCount < 2 || !dominantSpeaker || !domSpeakerStream?.hasAudio()) {
		return null;
	}

	// Analyze the dominant speaker to ensure that they are actively speaking to show the alert
	return (
		<AudioAnalyzer
			stream={domSpeakerStream}
			onSpeakingChange={setIsSpeaking}
			onIsTrackEnabledChanged={setIsEnabled}
		/>
	);
};

DominantSpeakerAlert.displayName = "DominantSpeakerAlert";

export default DominantSpeakerAlert;
