/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file disabled camera indicator component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.Video.DisabledCameraIndicator
 */

import React, { FC } from "react";
import Spinner from "~/components/Loading/Spinner";
import { useStrings } from "~/hooks";
import DisabledCamera from "~/icons/disabledCamera";
import LockedCamera from "~/icons/lockedCamera";
import styles from "./VideoPlaceholder.module.scss";

enum TokenNames {
	userDisabled = "UserDisabledTheirCamera",
	userLocked = "UserCameraLocked",
}

/**
 * Props for the DisabledCameraIndicator component
 */
interface IProps {
	locked?: boolean;
	/** If true, show a loading spinner instead of a disable icon */
	showSpinner?: boolean;
	/** Default Size - a value between 50px and 100px that will be used */
	defaultSize?: number;
}

/**
 * The DisabledCameraIndicator component
 * @param props The props ;)
 */
const DisabledCameraIndicator: FC<IProps> = (props) => {
	const { defaultSize, locked, showSpinner } = props;
	const strings = useStrings("DisabledCameraIndicator", Object.values(TokenNames));

	const title = locked ? strings[TokenNames.userLocked] : strings[TokenNames.userDisabled];
	const ariaLabel = title;

	const size = getIconSize(defaultSize);

	if (showSpinner) {
		return (
			<div className={styles["iconWrapper"]}>
				<Spinner />
			</div>
		);
	}

	if (locked) {
		return (
			<div className={styles["iconWrapper"]}>
				<LockedCamera
					height={size}
					width={size}
					title={title}
					aria-label={ariaLabel}
					data-testid="LockedCamera"
				/>
			</div>
		);
	}

	return (
		<div className={styles["iconWrapper"]}>
			<DisabledCamera
				fill="red"
				height={size}
				width={size}
				title={title}
				aria-label={ariaLabel}
				data-testid="DisabledCamera"
			/>
		</div>
	);
};

/**
 * Get the size of the disabled camera icon
 * @param defaultSize size passed in as a prop
 * @returns the size that the icon should be
 */
function getIconSize(defaultSize?: number): number {
	return Math.max(50, Math.min(100, defaultSize || 100));
}

DisabledCameraIndicator.displayName = "DisabledCameraIndicator";

export default DisabledCameraIndicator;
