/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file exit fullscreen icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.ExitFullscreen
 */

import React, { FC } from "react";
import { IconProps } from ".";
import styles from "../styles/_directional.scss";

export enum ExitFullscreenTestIds {
	self = "ExitFullscreen",
}

/**
 * The ExitFullscreen component
 * @param props The props ;)
 */
const ExitFullscreen: FC<IconProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			className={styles["flipWithPage"]}
			data-testid={ExitFullscreenTestIds.self}
			{...props}
		>
			<path d="M14,24H8V8H24v6a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H7A1,1,0,0,0,6,7V25a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1h0A1,1,0,0,0,14,24Z" />
			<rect
				x="10.75"
				y="5.74"
				width="2"
				height="12.02"
				transform="translate(-4.87 11.75) rotate(-45)"
			/>
			<path d="M18,18H12a1,1,0,0,1,0-2h4V12a1,1,0,0,1,2,0Z" />
			<path d="M20,20h6v5a1,1,0,0,1-1,1H20Z" />
		</svg>
	);
};

ExitFullscreen.displayName = "ExitFullscreen";

export default React.memo(ExitFullscreen);
